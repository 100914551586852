
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Product Category</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Product Category</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i class="fa fa-filter"></i>Filter</a>

            <div class="card-tools">
              <router-link :to="{ name: 'ProductCategoryAdd'}" class="btn btn-success">
                <i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body p-0">
            <form @submit.prevent="getList()" method="GET" novalidate>
              <div class="card-body" v-if="showFilter">
                <div class="row">

                </div>
                <div class="row">
                  <div class="col-10 offset-1">
                    <button type="submit" class="btn btn-primary m-2px">Search</button>
                    <button type="reset" class="btn btn-warning" @click="resetFilter()">Reset</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
                <tr>

                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>App-Id</th>
                  <th class="text-right">Action</th>

                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(val, index) in list.data">
                  <td>{{ val.id }}</td>
                  <td>{{ val.name }}</td>
                  <td>{{ val.description }}</td>
                  <td>{{ val.status }}</td>
                  <td>{{ val.app_id }}</td>
                  <td class="text-right">
                    <router-link v-if="actionView == true" :to="{ name: 'ProductCategoryShow', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link v-if="actionEdit == true" :to="{ name: 'ProductCategoryEdit', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                      <i class="fas fa-pencil-alt"></i>
                    </router-link>
                    <a v-if="actionDelete == true" class="btn btn-danger btn-sm m-2px" href="javascript:void(0)" @click="deleteObject(val.id, index)"><i class="fas fa-trash"></i></a>
                  </td>

                </tr>
              </tbody>
            </table>
            <div class="p-4" v-if="_.isEmpty(list.data) && is_loading == false">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination :pagination="list" v-if="list.total > list.per_page" @paginate="getList()"></vue-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import { CBSProductApi } from "@/services/api";

export default {
  name: "ProductCategory",
  components: {
    VuePagination
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 10,
      from: 1,
      to: 0,
      current_page: 1
    },
    actionView: false,
    actionEdit: true,
    actionDelete: false,
    showFilter: false,
    filter: {},
    dateRange: "",
    pre: [],
    is_loading: false,
    empty: false,
    empty_message: ""
  }),
  mounted: function () {
    this.getList();
  },
  methods: {
    getList: function () {
      this.is_loading = true;
      CBSProductApi.getProductCategory(this.list.current_page, this.filter, this.list.per_page)
        .then((items) => {
          this.list = items
        })
        .finally(() => {
          this.is_loading = false
        });
    },
    resetFilter () {
      this.list.current_page = 1
      this.filter = {}
      this.getList()
    }
  }
}
</script>
